import React from "react";
import * as S from "./Message.styled";
import Markdown from 'react-markdown'

interface MessageProps {
  content?: string;
  position: "left" | "right";
  id: string;
}

export const Message: React.FC<MessageProps> = ({
  content,
  position,
  id,
}) => {

  return (
    <S.Wrapper position={position} id={id}>
      <S.ContentWrapper position={position}><Markdown>{content}</Markdown></S.ContentWrapper>
    </S.Wrapper>
  );
};
