import styled from "styled-components";
import {mediaQueries} from "../../constants/mediaQuery.config";

export const Home = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  width: 100%;
  height: 100dvh;
  opacity: 1;

  font-family: "Jost";
  
    ${mediaQueries.mobile} {
      position: relative;
    }
`;

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 1rem;
`;

export const LandingHeading = styled.div`
  background-color: #ED430B;
  color: #FFFFFF;
  font-family: "Jost", sans-serif;
  padding: 0.25rem 0.5rem;
  margin: 5rem 0 3rem 2rem;
  align-self: flex-start;
  
  & > h2 {
    font-size: 1rem;
    font-weight: 500;
    margin: 0;
  }

    ${mediaQueries.tablet} {
        margin: 4rem 0 3rem 5rem;
        padding: 0.2rem 0.4rem;
        font-size: 0.9rem;
    }
  
    ${mediaQueries.mobile} {
      display: none;
    }
`;

export const LandingDescriptionContainer = styled.div`
  position: relative;
  background-color: #ED430B;
  padding: 1.2rem 2.4rem;
  border-radius: 12px;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  margin: 2rem 0;
  width: 36rem;
    
  ${mediaQueries.tablet} {
      flex-direction: column;
      justify-content: flex-start;
      margin-left: 0;
      margin-top: 0rem;
  }
    
  ${mediaQueries.mobile} {
      width: calc(90% - 4.8rem);
  }
`;

export const BackgroundPattern = styled.img`
    position: absolute;
    top: 0;
    right: 0;
    height: calc(100% - 2.4rem);
    margin: 1.2rem;
    z-index: 0;
`;

export const LandingDescriptionTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 0.5rem;
  z-index: 1;

  & > h2 {
    font-size: 1.7rem;
    font-weight: 600;
    margin: 0
  }
`;

export const LandingDescription = styled.div`
  font-size: 1.15rem;
  font-weight: 400;
  width: 20rem;
  max-width: 30rem;
  text-align: left;
  z-index: 1;


  ${mediaQueries.tablet} {
    width: 60%;
  }

  ${mediaQueries.mobile} {
      width: 100%;
  }
`;

export const SuggestionsContainer = styled.div`
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 1rem;
  margin-bottom: 1rem;
  gap: 0.5rem;
  
  & > p {
    margin: 0;
  }
  
  & > label {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9rem;
  }
    
    ${mediaQueries.mobile} {
        width: 100%;
    }
`;

export const Suggestion = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter", sans-serif;
  font-size: 0.7rem;
  border-radius: 3rem;
  border: 1px solid #838383;
  font-weight: 500;
  padding: 0.4rem 0.75rem;
  color: #838383;
  background-color: #fff;
  width: fit-content;
  cursor: pointer;
`;

export const InputWrapper = styled.div`
  width: min(45rem, 100%);
  max-width: 850px;
  padding: 0 0 1.5rem;

  margin-top: 1rem;
`;

export const InputTitleLabel = styled.div`
  position: relative;
  top: 1rem;
  left: 0.7rem;
  align-self: flex-start;
  margin: 0 1.25rem;
  
  & > img {
    width: 12rem;
    object-fit: cover;
  }
`;