import styled from "styled-components";

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  z-index: 100;
  margin: 0 auto;
  width: min(70rem, 95%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 2.4rem 1rem 4rem;
`;

export const Logo = styled.img`
    width: 180px;
`;
