import React, {useEffect} from "react";
import * as S from "./Chat.styled";
import { MessageT } from "../../types";
import { Instructions, MessageLoading, Message, ResultsSlider } from "../";
import {CustomCarousel} from "../CustomCarousel";

type MainProps = {
  messages: MessageT[];
  isWaitingForResponse: boolean;
  setLastUserMessageId: React.Dispatch<React.SetStateAction<string | number | null>>;
};

export const Main: React.FC<MainProps> = ({
  messages,
  isWaitingForResponse,
    setLastUserMessageId,
}) => {
  const containerRef = React.useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    containerRef.current?.scroll({
      top: containerRef?.current.scrollHeight,
      behavior: "smooth",
    });
  };

  React.useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    const lastMessageIndex = messages
        .slice()
        .reverse()
        .findIndex((message) => message.position === "right");

    setLastUserMessageId(messages.length - lastMessageIndex - 1);

  }, [messages]);

  return (
      <S.Main ref={containerRef}>
        {messages.map((message, index) => {
          return (
              <>
                <Message
                    id={`message-right-${index}`}
                    key={index}
                    content={message.content}
                    position={message.position}
                />
                {message.attachments && message.attachments.length > 0 && (
                    <ResultsSlider offers={message.attachments} key={`results-${index}`}/>
                )}
              </>
          );
        })}
        {isWaitingForResponse && <MessageLoading/>}
      </S.Main>
  );
};
