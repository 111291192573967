import React from "react";
import * as S from "./CookieNotice.styled";
import { Button } from "../Button";

type CookieNoticeProps = {
  onClick: () => void;
};

export const CookieNotice = ({onClick}: CookieNoticeProps) => {
  return (
    <S.Wrapper>
      <S.Container>
        <S.Text>We use cookies to improve your user experience on our website. By continuing to use our site you accept our <a href="https://suprabit.eu/privacy" target="_blank">Privacy Policy</a>.</S.Text>
        <S.StyledButton onClick={onClick}>OK</S.StyledButton>
      </S.Container>
    </S.Wrapper>
  );
};
