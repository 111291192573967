import React from "react";
import type { CardProps } from "../Card/Card";
import { Card } from "../Card/Card";
import {CustomCarousel} from "../CustomCarousel";

interface ResultsSliderProps {
  offers: CardProps[];
}

export const ResultsSlider: React.FC<ResultsSliderProps> = ({ offers }) => {
  const [cursorOnMouseDown, setCursorOnMouseDown] = React.useState({x: 0, y: 0});

  const handleOnMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setCursorOnMouseDown({
      x: e.clientX,
      y: e.clientY
    })
    e.preventDefault() // stops weird link dragging effect
  }

  const handleOnClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, url: string) => {
    const cursorOnMouseUp = {
      x: e.clientX,
      y: e.clientY
    }
    if (cursorOnMouseDown.x !== cursorOnMouseUp.x || cursorOnMouseDown.y !== cursorOnMouseUp.y) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      window.open(url, "_blank");
    }
  }

  const cards = offers.map((offer, index) => (
    <div onMouseDown={handleOnMouseDown}>
      <Card
        key={`${offer.title}-${index}`}
        {...offer}
        onClick={(e) => { return handleOnClick(e, offer.offerUrl) }}
      />
    </div>
  ));

  return (
      <CustomCarousel>
        {cards}
      </CustomCarousel>
  );
};
