import React from "react";
import * as S from "./Card.styled";

export interface CardProps {
  offerUrl: string;
  imageUrl: string;
  title: string;
  price?: string;
  price_without_vat?: string;
  key?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

export const Card: React.FC<CardProps> = ({
  offerUrl,
  imageUrl,
  title,
  key,
  price = "",
  price_without_vat,
  onClick,
}) => {
  return (
    <S.Card key={key} onClick={onClick}>
      <S.Thumbnail src={imageUrl}></S.Thumbnail>
      <S.Content>
        <S.Title>{title}</S.Title>
        <S.Price>
          {Intl.NumberFormat("de-DE", {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 0,
          }).format(Number(price ?? 0))}
        </S.Price>
        {price_without_vat && (
          <S.PriceWithoutVAT>
            Price without VAT: {" "} 
            {Intl.NumberFormat("de-DE", {
              style: "currency",
              currency: "EUR",
              minimumFractionDigits: 0,
            }).format(Number(price_without_vat ?? 0))}
          </S.PriceWithoutVAT>
        )}
      </S.Content>
    </S.Card>
  );
};
