import React, { FC, useEffect } from "react";
import * as S from "./Chat.styled";
import { Input } from "../Input/Input";
import { Main } from "./Main";
import { ChatHeader } from "../ChatHeader";
import { useLocation } from "react-router";
import { useAssistantChat } from "../../context";
import { IChatProps } from "../../templates/Chat/Chat";
import { GreenBubble } from "./Chat.styled";
import GreenBubbleSvg from "./green_bubble.svg";

export const Chat: FC<IChatProps> = ({ scrollToBottom }) => {
  const {
    messages,
    isSendingMessage,
    isWaitingForResponse,
    sendMessage,
    createNewConversation,
    setIsWaitingForResponse,
  } = useAssistantChat();
  const [inputValue, setInputValue] = React.useState("");

  const containerRef = React.useRef<HTMLDivElement>(null);
  const [lastUserMessageIndex, setLastUserMessageIndex] = React.useState<string | number | null>(null);

  // @ts-ignore
  const { state } = useLocation();

  useEffect(() => {
    if(lastUserMessageIndex && !isWaitingForResponse)
      scrollToBottom(lastUserMessageIndex);
  }, [messages, lastUserMessageIndex, isWaitingForResponse]);

  const handleSendMessage = async () => {
    sendMessage(inputValue);
    setInputValue("");
  };

  useEffect(() => {
    const events = ["load", "visibilitychange"];

    const handlePageshow = (event: Event) => {
      if (
        event.type === "visibilitychange" &&
        document.visibilityState !== "visible"
      )
        return;

      console.log(`Page event: ${event.type}`);
      const localThread = localStorage.getItem("thread");
      if (localThread) {
        setIsWaitingForResponse(true);
      } else {
        createNewConversation();
      }
    };
    // Listen to pageshow event, reconnect to the thread if it exists
    events.forEach((event) => {
      window.addEventListener(event, handlePageshow);
    });

    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, handlePageshow);
      });
    };
  }, []);

  useEffect(() => {
    const message = state?.message;
    if (message) {
      console.log(`Received message from state: ${message}`);
      window.history.replaceState({}, "");
      sendMessage(message);
    }
  }, [state?.message]);

  const isWaiting = isWaitingForResponse || isSendingMessage;

  return (
    <S.Container ref={containerRef}>
      <ChatHeader />
      <S.ChatWrapper>
        <S.IntroMessage>
          <GreenBubble src={GreenBubbleSvg} alt={"green bubble"} />
          {"Hi! Let's chat and find a great car for you!"}
        </S.IntroMessage>
        <Main messages={messages} isWaitingForResponse={isWaiting} setLastUserMessageId={setLastUserMessageIndex}/>
        <S.Footer>
          {/* <Button
            onClick={handleNewConversation}
          /> */}
          <S.InputWrapper>
            <Input
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder={messages?.length > 0 ? "Continue conversation..." : "Start conversation..."}
              onKeyDown={(e) =>
                e.key === "Enter" && inputValue && handleSendMessage()
              }
              disabled={isWaiting}
              onSend={handleSendMessage}
              isDisabled={isWaiting || !inputValue}
            />
          </S.InputWrapper>
        </S.Footer>
      </S.ChatWrapper>
    </S.Container>
  );
};
