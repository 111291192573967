interface IMediaQueriesPixels {
    mobile: number;
    tablet: number;
    desktop: number;
    desktopLarge: number;
}

export const deviceMediaQueriesPixels: IMediaQueriesPixels = {
    mobile: 600,
    tablet: 1024,
    desktop: 1366,
    desktopLarge: 1920,
};

export const  mediaQueries = {
    mobile: `@media (max-width: ${deviceMediaQueriesPixels.mobile}px)`,
    tablet: `@media (max-width: ${deviceMediaQueriesPixels.tablet}px)`,
    desktop: `@media (max-width: ${deviceMediaQueriesPixels.desktop}px)`,
    desktopLarge: `@media (max-width: ${deviceMediaQueriesPixels.desktopLarge}px)`,
    desktopLargeLarge: `@media (min-width: ${deviceMediaQueriesPixels.desktopLarge}px)`
}