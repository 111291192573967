// context for storing messages

import React, { createContext, useCallback, useEffect, useRef, useState } from "react";
import { APIResponseT, AssistantMessage, MessageT } from "../types";
import axios from "axios";
import ReconnectingWebSocket from "reconnecting-websocket";
import { CardProps } from "../components";

const BACKEND_URL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_BACKEND_URL_PROD
    : process.env.REACT_APP_BACKEND_URL_LOCAL;

type AssistantChatContextT = {
  messages: MessageT[];
  thread: string;
  setMessages: React.Dispatch<React.SetStateAction<MessageT[]>>;
  sendMessage: (message: string) => Promise<void>;
  connectToThreadStatus: () => void;
  disconnectFromThreadStatus: () => void;
  createNewConversation: () => Promise<void>;
  clearThread: () => Promise<void>;
  isWaitingForResponse: boolean;
  setIsWaitingForResponse: React.Dispatch<React.SetStateAction<boolean>>;
  isSendingMessage: boolean;
  setIsSendingMessage: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AssistantChatContext = createContext<AssistantChatContextT>({
  messages: [],
  thread: "",
  setMessages: () => {},
  sendMessage: () => Promise.resolve(),
  connectToThreadStatus: () => {},
  disconnectFromThreadStatus: () => {},
  createNewConversation: () => Promise.resolve(),
  isWaitingForResponse: false,
  setIsWaitingForResponse: () => {},
  isSendingMessage: false,
  setIsSendingMessage: () => {},
  clearThread: async () => {},
});

type MessagesProviderProps = {
  children: React.ReactNode;
};

export const AssistantChatProvider: React.FC<MessagesProviderProps> = ({
  children,
}) => {
  const abortControllerRef = useRef(new AbortController());
  const [thread, setThread] = useState<string>(localStorage.getItem("thread") ?? "");
  const [messages, setMessages] = useState<MessageT[]>(JSON.parse(localStorage.getItem("messages") ?? "[]"));
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const ws = useRef<ReconnectingWebSocket | null>(null);

  useEffect(() => {
    // Store messages in local storage
    localStorage.setItem("messages", JSON.stringify(messages));
  }, [messages]);

  useEffect(() => {
    // Store thread in local storage
    if (thread && localStorage.getItem("thread") !== thread) {
      console.log(`Thread changed: ${thread}`);
      localStorage.setItem("thread", thread);
    }
  }, [thread]);

  useEffect(() => {
    // Listen to the ws endpoint thread-status
    if (!thread || !isWaitingForResponse) return;

    connectToThreadStatus();

    return () => {
      ws?.current?.close();
    };
  }, [isWaitingForResponse, thread]);

  useEffect(() => {
    const localMessages = localStorage.getItem("messages");
    let parsedMessages: MessageT[] = [];
    if (localMessages) {
      parsedMessages = JSON.parse(localMessages);
    }

    if (parsedMessages.length > 0) {
      console.log(`Messages found: ${parsedMessages.length}`)
      setMessages(parsedMessages);
    }

    const localThread = localStorage.getItem("thread");
    if (localThread) {
      console.log(`Thread found: ${localThread}`)
      setThread(localThread);
      // setIsWaitingForResponse(true);
    } else {
      //createNewConversation();
    }
  }, []);

  const processAssistantMessages = (assistantResponse: AssistantMessage[]): MessageT[] => {
    return assistantResponse?.map((message) => {
      const attachments: CardProps[] = [];

      let msg = message.content[0].value;
      console.log("msg", msg);

      msg = msg.split("***")[0];

      const pattern = /\[*\s*{[^{}]*}\s*(?:,\s*{[^{}]*}\s*)*\]*/;

      // Check if JSON is still in the message, if so, remove it
      const jsonLocation = pattern.exec(message.content[0].value);
      if (jsonLocation?.index) {
        msg = msg.substring(0, jsonLocation.index);
      }

      const match = message.content[0].value.match(pattern);
      let jsonData = [];

      if (match) {
        jsonData = JSON.parse(match[0]);
        console.log("JSON", jsonData);
      } else {
        console.log("No match found.");
      }

      if (jsonData.length > 0) {
        jsonData.forEach((attachment: any) => {
          if (typeof attachment.price === "string") {
            // If price is returned as xxx € or xxx EUR, split it and take the first part
            attachment.price = attachment.price.split(" ")[0];
          }
          if (typeof attachment.price_without_vat === "string") {
            // If price is returned as xxx € or xxx EUR, split it and take the first part
            attachment.price_without_vat = attachment.price_without_vat.split(" ")[0];
          }
          attachments.push({
            offerUrl: attachment.url,
            imageUrl: attachment.image,
            title: attachment.title,
            price: attachment.price.toString(),
            price_without_vat: attachment.price_without_vat ? attachment.price_without_vat.toString() : null
          });
        });
      }

      return {
        content: msg,
        position: message.role === "assistant" ? "left" : "right",
        attachments: attachments,
      } as MessageT;
    });
  };

  const disconnectFromThreadStatus = () => {
    if (ws.current) {
      ws.current.close();
    }
  };

  const connectToThreadStatus = useCallback(() => {
    const wsUrl = new URL(`${BACKEND_URL}/thread-status?threadId=${thread}`);
    if(wsUrl.protocol === "https:") {
      wsUrl.protocol = "wss:";
    } else {
      wsUrl.protocol = "ws:";
    }
    ws.current = new ReconnectingWebSocket(`${wsUrl}`, [], {
      maxRetries: 10,
      connectionTimeout: 3000
    });

    ws.current.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log(`Received message from ws: ${JSON.stringify(data)}`);
      if (data.status === "completed") {
        const messages = processAssistantMessages(data.messages);
        if(messages?.length > 0) {
          setMessages([...messages.reverse()]);
        }
        setIsWaitingForResponse(false);
      } else if(data.status === "failed" || data.status === "cancelled") {
        setMessages([
          {
            content: "Sorry, something went wrong. Please start a new conversation.",
            position: "left",
          }
        ]);
        setIsWaitingForResponse(false);
      }
    };
  }, [thread]);

  const sendMessage = async (message: string) => {
    if (!message) {
      setIsWaitingForResponse(false);
      return;
    }
    // set messages to current messages + new message
    setIsSendingMessage(true);
    setMessages([
      ...messages,
      {
        content: message,
        position: "right",
      },
    ]);

    try {
      await axios.get<APIResponseT>(`${BACKEND_URL}/ask`, {
        params: { threadId: thread, message },
        signal: abortControllerRef.current.signal
      });
      setIsSendingMessage(false);
      setIsWaitingForResponse(true);
    } catch (err) {
      console.log(err);
      setMessages([
        ...messages,
        {
          content: `Sorry, something went wrong. Please start a new conversation. Error: ${err}`,
          position: "left",
        },
      ]);
      setIsWaitingForResponse(false);
    } finally {
      setIsSendingMessage(false);
    }
  };

  const abortActiveRequests = () => {
    abortControllerRef.current.abort();
    abortControllerRef.current = new AbortController();
  }

  const createThread = async () => {
    const res = await axios.get(`${BACKEND_URL}/createThread`);
    setThread(res.data);
  };

  const createNewConversation = async () => {
    abortActiveRequests();
    await createThread();
    setMessages([]);
    setIsWaitingForResponse(false);
  };

  const clearThread = async () => {
    setThread("");
    setMessages([]);
    localStorage.removeItem("thread");
    localStorage.removeItem("messages");
    setIsWaitingForResponse(false);
    disconnectFromThreadStatus();
    abortActiveRequests();
  }

  return (
    <AssistantChatContext.Provider
      value={{
        messages,
        thread,
        setMessages,
        sendMessage,
        connectToThreadStatus,
        disconnectFromThreadStatus,
        createNewConversation,
        isWaitingForResponse,
        setIsWaitingForResponse,
        isSendingMessage,
        setIsSendingMessage,
        clearThread
      }}
    >
      {children}
    </AssistantChatContext.Provider>
  );
};

export const useAssistantChat = () => React.useContext(AssistantChatContext);
