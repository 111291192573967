import styled, { css } from "styled-components";
import {mediaQueries} from "../../constants/mediaQuery.config";

type WrapperProps = {
  position: "left" | "right";
};

const getArrowStyles = (position: "left" | "right") => {
  if (position === "left") {
    return css`
      background-color: #B3DC58;
      left: -10px;
    `;
  } else {
    return css`
      background-color: #F4941A;
      right: -10px;
    `;
  }
};

export const ContentWrapper = styled.div<WrapperProps>`
  background-color: "#F4F4F4";
  color: "#363636";
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  padding: 11px;
  border-radius: 11px;
  max-width: 440px;
  word-break: break-word;
  position: relative;
  margin: 7px 15px 0 15px;
  white-space: pre-line;
  ${({ position }) => (position === "left" ? "text-align: left" : "text-align: right")};
  

  &::before {
    content: "";
    position: absolute;
    width: 4px;
    height: 100%;
    border-radius: 4px;
    top: 0px;
    ${({ position }) => getArrowStyles(position)}
  }

  p {
    margin: 0;
  }

  ol, ul{
    white-space: normal;
  }

  li{
    margin-top: 15px;
    margin-right: 10px;
  }

  img {
    max-width: 100%;
    height: auto;
    border-radius: 11px;
    margin-top: 10px;
  }

  a{
    color: "#363636";
    text-decoration: underline;
  }

  ${mediaQueries.mobile} {
    font-size: 14px;
  }


`;

export const Wrapper = styled.div<WrapperProps>`
  margin: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  /* animated appear */
  animation: appear 0.2s ease-in-out;
  animation-fill-mode: forwards;
  align-self: ${({ position }) =>
    position === "left" ? "flex-start" : "flex-end"};

  @keyframes appear {
    0% {
      opacity: 0;
      transform: translateX(
        ${({ position }) => (position === "left" ? "-100%" : "100%")}
      );
    }

    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
`;
