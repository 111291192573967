import React, {FC, useState, useRef, MouseEvent, PropsWithChildren} from "react";
import * as S from "./CustomCarousel.styled";

export const CustomCarousel: FC<PropsWithChildren> = ({ children }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [startX, setStartX] = useState<number>(0);
    const [scrollLeft, setScrollLeft] = useState<number>(0);

    const handleMouseDown = (e: MouseEvent<HTMLDivElement>) => {
        if (!containerRef.current) return;
        setIsDragging(true);
        setStartX(e.pageX - containerRef.current.offsetLeft);
        setScrollLeft(containerRef.current.scrollLeft);
    };

    const handleMouseMove = (e: MouseEvent<HTMLDivElement>) => {
        if (!isDragging) return;
        if (!containerRef.current) return;
        const x = e.pageX - containerRef.current.offsetLeft;
        const walk = (x - startX);
        containerRef.current.scrollLeft = scrollLeft - walk;
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleMouseLeave = () => {
        setIsDragging(false);
    };


    return (
        <S.Container>
            <S.CarouselContent
                ref={containerRef}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseLeave}
            >
                {children}
            </S.CarouselContent>
            <S.LeftShadow />
            <S.RightShadow />
        </S.Container>
    );
};
