import React from "react";
import * as S from "./LandingFooter.styled";
import LogoFooter from "./LogoSvg";

export const LandingFooter = () => {
  return (
    <S.Wrapper>
      <LogoFooter />
      <span>
        <p>Copyright © {new Date().getFullYear()} Suprabit Ltd.</p>{" "}
        <p> All rights reserved.</p>
        <a href="https://www.suprabit.eu" target="_blank" rel="noreferrer">
          www.suprabit.eu
        </a>
      </span>
    </S.Wrapper>
  );
};
